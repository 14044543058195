<script>
import { mapState } from "vuex";

export default {
  name: "SidebarItems",
  data() {
    return {
      //Common
      financialManagement: {
        dropdownMode: "closeInactive",
        _name: "CSidebarNavDropdown",
        name: "حساب مالی من",
        items: [
          {
            name: "خرید خدمات ماهواره ای",
            to: "/buySentinelService",
          },
          {
            name: "خدمات خریداری شده",
            to: "/farmShopReport",
          },
          {
            name: "گزارشات و تراکنش های مالی",
            to: "/financialUserReports",
          },
          {
            name: "جشنواره ها و کدهای تخفیف",
            to: "/financialDiscount",
          },
        ],
      },
      advice: {
        _name: "CSidebarNavItem",
        name: "دستورالعمل ها",
        to: "/advice",
      },
      dietPlan: {
        _name: "CSidebarNavItem",
        name: "برنامه غذایی",
        to: "/dietPlan",
      },
      cooperationResponse: {
        _name: "CSidebarNavItem",
        name: "درخواست های همکاری",
        to: "/cooperationResponse",
      },
      employeeFarmAction: {
        _name: "CSidebarNavDropdown",
        name: "عملیات ی زمین",
        items: [
          {
            name: "تنش ها",
            to: "/tension",
          },
          {
            name: "عملیات ",
            to: "/operation",
          },
        ],
      },
      support: {
        _name: "CSidebarNavItem",
        name: "پشتیبانی",
        to: "/support",
      },

      //Farmer
      newFarm: {
        _name: "CSidebarNavDropdown",
        dropdownMode: "closeInactive",
        name: "ثبت زمین",
        items: [
          {
            name: "با فایل KML",
            to: "/farmAddKML",
          },
          {
            name: "با نقشه",
            to: "/farmAddMap",
          },
          {
            name: "از آرشیو",
            to: "/farmAddArchive",
          },
        ],
      },
      farmAction: {
        _name: "CSidebarNavDropdown",
        dropdownMode: "closeInactive",
        name: "مدیریت زمین ها",
        items: [
          {
            name: "تنش ها",
            to: "/tension",
          },
          {
            name: "عملیات ",
            to: "/operation",
          },
          {
            name: "هزینه ها",
            to: "/cost",
          },
          {
            name: "تأسیسات",
            to: "/geoDataBase",
          },
        ],
      },
      cooperationRequest: {
        _name: "CSidebarNavItem",
        name: "درخواست همکاری",
        to: "/cooperationRequest",
      },
      farmerAdvicer: {
        _name: "CSidebarNavItem",
        name: "متخصصان من",
        to: "/conFarmerAdvicer",
      },
      farmerFarmuser: {
        _name: "CSidebarNavItem",
        name: "کارمندان مزرعه من",
        to: "/conFarmerFarmuser",
      },

      //farmUser
      farmuserFarms: {
        _name: "CSidebarNavItem",
        name: "کارفرمایان",
        to: "/farmuserFarms",
      },
      farmuserTips: {
        _name: "CSidebarNavItem",
        name: "توصیه های اختصاصی",
        to: "/farmuserTips",
      },

      //agnet
      agentFarmer: {
        _name: "CSidebarNavItem",
        name: "کشاورزان",
        to: "/conAgentFarmer",
      },

      //advicer

      advicerFarms: {
        _name: "CSidebarNavItem",
        name: "کارفرمایان",
        to: "/advicerFarms",
      },
      advicerTips: {
        _name: "CSidebarNavItem",
        name: "توصیه های اختصاصی",
        to: "/advicerTips",
      },
    };
  },

  computed: {
    ...mapState({
      LoginedFullName: (state) =>
        state.login.type == null
          ? state.register.fullName == ""
            ? state.register.username
            : state.register.fullName
          : state.login.fullName == ""
          ? state.login.username
          : state.login.fullName,
      DisplayFarmUser: (state) =>
        state.login.type == null ? state.register.type : state.login.type,
      FarmerType: (state) =>
        state.login.type == null
          ? state.register.user.moreInfo.farmerType
          : state.login.user.moreInfo.farmerType,
      UserId: (state) =>
        state.login.type == null
          ? state.register.user.userId
          : state.login.user.userId,
    }),

    BaseItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: [
            {
              _name: "CSidebarNavItem",
              name: "لیست زمین ها",
              to: "/farms",
            },
            {
              _name: "CSidebarNavItem",
              name: "محصولات",
              to: "/product",
            },
          ],
        },
      ];
    },

    farmerSidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.newFarm);
      selected._children.push(this.farmAction);
      selected._children.push(this.financialManagement);
      selected._children.push(this.cooperationRequest);
      selected._children.push(this.advice);
      selected._children.push(this.dietPlan);
      selected._children.push(this.farmerAdvicer);
      selected._children.push(this.farmerFarmuser);
      selected._children.push(this.support);

      return returnList;
    },

    farmuserSidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.employeeFarmAction);
      selected._children.push(this.advice);
      selected._children.push(this.dietPlan);
      selected._children.push(this.cooperationResponse);
      selected._children.push(this.farmuserFarms);
      selected._children.push(this.farmuserTips);
      selected._children.push(this.support);

      return returnList;
    },

    agencySidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.agentFarmer);
      selected._children.push(this.financialManagement);
      selected._children.push(this.advice);
      selected._children.push(this.dietPlan);
      selected._children.push(this.support);

      return returnList;
    },

    advicerSidebarItems() {
      let returnList = this.BaseItems;
      let selected = returnList[0];
      selected._children.push(this.employeeFarmAction);
      selected._children.push(this.advice);
      selected._children.push(this.dietPlan);
      selected._children.push(this.cooperationResponse);
      selected._children.push(this.advicerFarms);
      selected._children.push(this.advicerTips);
      selected._children.push(this.support);

      return returnList;
    },

    sidebarItems() {
      switch (
        this.DisplayFarmUser
          ? this.DisplayFarmUser.toLowerCase()
          : this.DisplayFarmUser
      ) {
        case "farmer":
          return this.farmerSidebarItems;
        case "farmuser":
          return this.farmuserSidebarItems;
        case "agency":
          return this.agencySidebarItems;
        case "advicer":
          return this.advicerSidebarItems;
        default:
          return this.BaseItems;
      }
    },
  },
};
</script>
<style>
.navOne {
  color: blueviolet;
}
</style>
